<template>
  <v-container fluid class="mt-10">
    <v-card elevation="0" tile>
      <v-card-title class="overline">
        <v-btn
          text
          @click="$router.back()"
          v-if="$vuetify.breakpoint.smAndDown"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          Back
        </v-btn>

        2021 Appraisal Period
        <v-spacer />

        templates

        <v-spacer />

        <v-chip x-small dark color="green"> Open </v-chip>
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="3">
            <v-list subheader>
              <v-subheader class="font-weight-bolder text-h6">
                Templates
                <v-spacer />

                <v-chip small dark class="blue">{{ templates.length }}</v-chip>
              </v-subheader>
              <v-list-group
                v-for="(template, t) in templates"
                :key="t"
                v-model="template.active"
              >
                <template v-slot:activator>
                  <v-list-item-action>
                    <v-checkbox v-model="selected" multiple :value="template" />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      template.description
                    }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item-group>
                  <v-list-item
                    link
                    :to="`/appraisal/template/${group.Template}/${group.groupCode}`"
                    v-for="(group, g) in template.groups"
                    :key="g"
                    active-class="font-weight-bold primary--text"
                    style="box-shadow: none"
                  >
                    <v-list-item-avatar> &nbsp; </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        group.Description
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        >Weight: {{ group.Weight }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>mdi-menu-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list-group>
            </v-list>
          </v-col>

          <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
            <v-divider />
          </v-col>
          <v-divider v-else vertical />

          <v-col cols="12" md="3">
            <v-list subheader>
              <v-subheader class="font-weight-bolder text-h6">
                Objectives
                <v-spacer />

                <v-chip small dark class="green">{{
                  objectives.length
                }}</v-chip>
              </v-subheader>
              <v-list-item-group>
                <v-list-item
                  link
                  :to="`/appraisal/template/${$route.params.template}/${objective.groupCode}/${objective.objective}`"
                  v-for="(objective, g) in objectives"
                  :key="g"
                  active-class="font-weight-bold primary--text"
                  style="box-shadow: none"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{
                      objective.description
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      >Weight: {{ objective.weight }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-menu-right</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>

              <v-list-item v-if="objectives.length === 0">
                <v-list-item-title class="text-center font-weight-light"
                  >Select a group</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-col>

          <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
            <v-divider />
          </v-col>
          <v-divider v-else vertical />

          <v-col cols="12" md="5">
            <v-list three-line subheader>
              <v-subheader class="font-weight-bolder text-h6">
                Key Performance Indicators
                <v-spacer />

                <v-chip small dark class="deep-orange">{{
                  indicators.length
                }}</v-chip>
              </v-subheader>
              <v-list-item-group>
                <v-list-item
                  v-for="(indicator, g) in indicators"
                  :key="g"
                  active-class="font-weight-bold primary--text"
                  style="box-shadow: none"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ indicator.code }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      indicator.description
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>

              <v-list-item v-if="indicators.length === 0">
                <v-list-item-title class="text-center font-weight-light"
                  >Select an objective</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Templates",

  data: function () {
    return {
      selected: [],
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("appraisal/getTemplates");
    });
  },

  computed: {
    templates() {
      return this.$store.getters["appraisal/templates"];
    },

    groups() {
      const templates = this.templates
        .filter((t) => {
          return t.code === this.$route.params.template;
        })
        .shift();

      return templates ? templates.groups : [];
    },

    objectives() {
      const group = this.groups
        .filter((t) => {
          return (
            t.groupCode === this.$route.params.group &&
            t.Template === this.$route.params.template
          );
        })
        .shift();
      console.log(this.groups);
      return !group ? [] : group.objectives;
    },

    indicators() {
      const objective = this.objectives
        .filter((obj) => {
          return obj.objective === this.$route.params.objective;
        })
        .shift();

      return objective ? objective.indicators : [];
    },
  },
};
</script>

<style scoped></style>
