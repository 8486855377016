import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VDialog,{attrs:{"persistent":"","overlay-opacity":"0.5","width":"80%"},model:{value:(_vm.leaveApplicationReportDialog.open),callback:function ($$v) {_vm.$set(_vm.leaveApplicationReportDialog, "open", $$v)},expression:"leaveApplicationReportDialog.open"}},[_c(VCard,{attrs:{"tile":""}},[_c(VBtn,{staticStyle:{"position":"absolute","right":"1em"},style:({
          float: 'right',
          color: '#000',
          marginTop: '1%',
        }),attrs:{"icon":""},on:{"click":function($event){_vm.leaveApplicationReportDialog = false}}},[_c(VIcon,{attrs:{"size":"30"}},[_vm._v("mdi-close")])],1),_c(VCardTitle,[_vm._v(" Leave Application Report ")]),_c(VCardText,[_c('div',{attrs:{"id":"leaveApplicationReport"}},[_c('iframe',{ref:"leaveApplicationReport",staticStyle:{"width":"100%","height":"100vh","border":"none"},attrs:{"title":"leaveApplicationReport","src":("data:application/pdf;base64," + (_vm.leaveApplicationReportDialog.leaveApplicationReport)),"allow":"autoplay","loading":"lazy"}})])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }