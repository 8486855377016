import LeaveLinks from "./modules/leave/LeaveLinks";
import handoverLinks from "./modules/handover/handoverLinks";

const links = [...LeaveLinks, ...handoverLinks];

export default {
  order: 1,
  name: "Leave Management",
  icon: "date_range",
  links,
  hasSideLinks: true,
  enabled: true,
};
