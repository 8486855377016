<template>
  <v-container>
    <v-dialog
      persistent
      overlay-opacity="0.5"
      v-model="leaveSheetDialog.open"
      width="80%"
    >
      <v-card tile>
        <v-btn
          icon
          :style="{
            float: 'right',
            color: '#000',
            marginTop: '1%',
          }"
          style="position: absolute; right: 1em"
          @click="leaveSheetDialog = false"
        >
          <v-icon size="30">mdi-close</v-icon>
        </v-btn>
        <v-card-title> Leave Sheet </v-card-title>
        <v-card-text>
          <div id="leaveSheet">
            <iframe
              ref="leaveSheet"
              title="leaveSheet"
              :src="`data:application/pdf;base64,${leaveSheetDialog.leaveSheet}`"
              style="width: 100%; height: 100vh; border: none;"
              allow="autoplay"
              loading="lazy"
            ></iframe>
          </div>
        </v-card-text>
        <!-- <v-card-actions>
          <v-btn
            @click="PrintElem('leaveSheet')"
            style="position: fixed; right: 12%; bottom: 10%"
            color="primary"
            rounded
          >
            <v-icon class="mx-3">print</v-icon>
            Print
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
// import pdf from "vue-pdf";
export default {
  name: "LeaveSheet",
  // components: { pdf },
  data: () => ({}),
  computed: {
    ...mapState({}),
    leaveSheetDialog: {
      get() {
        return this.$store.state.leave.leaveSheetDialog;
      },
      set(value) {
        this.$store.commit("leave/SET_LEAVE_SHEET", {
          open: value,
          leaveSheet: "",
        });
      },
    },
  },
  methods: {
    ...mapActions({}),
    PrintElem() {
      this.$refs.leaveSheet.print();
    },
  },
};
</script>
