<template>
  <v-data-table
    :headers="headers"
    :items="applications"
    :sort-desc="[false, true]"
    multi-sort
    class="elevation-1"
    v-if="!isCalender"
    :search="listFilterValue"
    ref="leave_list"
    id="scollStyle"
  >
    <!--eslint-disable-next-line -->
    <template v-slot:item.status="{ item }">
      <v-chip label :color="getColor(item.status).color" small>
        {{ item.status }}
      </v-chip>
    </template>
    <!--eslint-disable-next-line -->
    <template v-slot:item.made_by="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            v-if="item.made_by !== undefined"
            class="d-flex-inline justify-start"
          >
            <v-avatar
              size="28"
              class="primary lighten-4 primary--text text--darken-4"
              style="cursor: pointer"
              v-bind="attrs"
              v-on="on"
            >
              {{ item.employeeInitials }}
            </v-avatar>
            {{ item.fullName }}
          </div>
          <div v-else></div>
        </template>
        <span v-if="item.made_by !== undefined">{{
          item.made_by.first_name + " " + item.made_by.last_name
        }}</span>
      </v-tooltip>
    </template>
    <!--eslint-disable-next-line -->
    <template v-slot:item.days_applied="{ item }">
      <div style="font-size: 14px; font-weight: 400">
        {{ `${item.days_applied} day(s)` }}
      </div>
    </template>
    <!--eslint-disable-next-line -->
    <template v-slot:item.start_date="{ item }">
      {{ getDateFormat(item.start_date) }}
    </template>
    <!--eslint-disable-next-line -->
    <template v-slot:item.return_date="{ item }">
      {{ getDateFormat(item.return_date) }}
    </template>
    <!--eslint-disable-next-line -->
    <template v-show="isApprover" v-slot:item.approvalEntries="{ item }">
      <div class="text-lg-h6" v-if="item.approvalEntries !== undefined">
        {{ item.approvalEntries.length }}
      </div>
    </template>
    <!--eslint-disable-next-line -->
    <template v-slot:item.actions="{ item }">
      <div class="d-flex justify-start">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              class="primary lighten-4 mx-1"
              v-bind="attrs"
              v-on="on"
              label
              @click="viewApplication(item)"
            >
              <v-icon color="primary darken-4">pageview</v-icon>
            </v-chip>
          </template>
          <span>view application</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              class="primary lighten-4 mx-1"
              label
              v-bind="attrs"
              v-on="on"
              v-show="item.status === 'Released' || item.status === 'Posted'"
              @click="getLeaveSheet(item.application_code)"
            >
              <v-icon color="primary darken-4">article</v-icon>
            </v-chip>
          </template>
          <span>view leave sheet</span>
        </v-tooltip>
        <div class="d-flex justify-start" v-if="!item.isApprover">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="primary lighten-4 mx-1"
                label
                v-bind="attrs"
                v-on="on"
                v-show="item.status === 'Open'"
                @click="crudAction(item, 'approval')"
              >
                <v-icon color="primary darken-4">schedule_send</v-icon>
              </v-chip>
            </template>
            <span>send for approval</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="primary lighten-4 mx-1"
                label
                v-bind="attrs"
                v-on="on"
                v-show="item.status === 'Open'"
                @click="editApplication(item.application_code)"
              >
                <v-icon color="primary darken-4">mode_edit</v-icon>
              </v-chip>
            </template>
            <span>Edit Application</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="primary lighten-4 mx-1"
                label
                v-bind="attrs"
                v-on="on"
                v-show="item.status === 'Open'"
                @click="crudAction(item, 'archive')"
              >
                <v-icon color="primary darken-4">event_busy</v-icon>
              </v-chip>
            </template>
            <span>delete application</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="primary lighten-4 mx-1"
                label
                v-bind="attrs"
                v-on="on"
                v-show="item.status === 'Review'"
                ripple
                @click="crudAction(item, 'cancel')"
              >
                <v-icon color="primary darken-4">cancel_schedule_send</v-icon>
              </v-chip>
            </template>
            <span>cancel approval</span>
          </v-tooltip>
        </div>
      </div>
    </template>
  </v-data-table>
</template>
<script>
/* eslint-disable camelcase */
import { leaveMixin, SetupMixin } from "../../../mixins";
import LeaveMethods from "../LeaveMethods";
export default {
  components: {},
  name: "LeaveList",
  props: { isApprover: Boolean, isCalender: Boolean },
  mixins: [leaveMixin, SetupMixin],
  data: function () {
    return {
      scroll: true,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.getSetup();
    });
  },
  computed: {
    handoverRequired: function () {
      return this.$store.getters["leaveSetup/setupGetter"]("handoverSetup")
        .handover;
    },
    headers: function () {
      return [
        {
          text: "#Code",
          align: "start",
          sortable: true,
          value: "application_code",
        },
        { text: "Made By", sortable: true, value: "made_by" },
        { text: "Applied", sortable: true, value: "days_applied" },
        { text: "Leave Type", sortable: true, value: "leave_code" },
        { text: "Period", sortable: true, value: "leave_period" },
        { text: "Start Date", sortable: true, value: "start_date" },
        { text: "Return Date", sortable: true, value: "return_date" },
        {
          text: this.isApprover ? "Approval Entries" : "",
          sortable: true,
          value: this.isApprover ? "approvalEntries" : "",
        },
        { text: "Status", sortable: true, value: "status" },
        { text: "Actions", sortable: false, value: "actions" },
      ];
    },
    applications: function () {
      const data = this.getApplications().map((app) => {
        app = app.leaveData;
        return app;
      });
      return data;
    },
    queryStats: function () {
      return this.$store.getters["leave/leaveGetter"]("setQuery");
    },
  },
  watch: {
    applications() {
      if (this.applications.length >= 8) {
        this.scroll = false;
      } else {
        this.scroll = true;
      }
    },
    isApprover() {
      console.log("isApprover", this.isApprover);
    },
    queryStats() {
      if (!this.isApprover) {
        this.$router.replace({
          path: "/leave/application",
          query: { id: this.queryStats.toString() },
        });
      }
    },
  },
  methods: {
    ...LeaveMethods,
    Accept: function (val) {
      if (val.acceptHandover === true) {
        return "Accepted";
      } else {
        if (val.acceptHandover === false && val.handoverComment !== "") {
          return "Rejected";
        }
      }
    },
    buttonStyles(val) {
      if (val.acceptHandover === true) {
        return "success";
      } else {
        if (val.acceptHandover === false && val.handoverComment !== "") {
          return "error";
        }
      }
    },
  },
};
</script>
