<template>
  <v-card class="mx-auto" flat>
    <v-card-title>
      My Approval List
      <v-spacer />
      <v-toolbar dense flat>
        <v-btn-toggle
          v-model="tabs"
          active-class="toggle--active"
          borderless
          dense
          mandatory
          rounded
        >
          <v-btn
            v-for="(item, i) in status"
            :key="i"
            :color="item === tabs ? 'primary' : 'default'"
            :elevation="item === tabs ? 8 : 0"
            :rounded="item === tabs"
            :value="item"
            small
          >
            <span
              :style="{ color: item === tabs ? '#ffffff' : '' }"
              class="text-capitalize"
              >{{
                item === "Released"
                  ? "Approved"
                  : item && item === "Pending Approval"
                  ? "Pending"
                  : item
              }}</span
            >
          </v-btn>
        </v-btn-toggle>
      </v-toolbar>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        dense
        outlined
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-divider />
    <v-row>
      <v-col cols="12">
        <v-chip-group
          v-model="selectedGroup"
          active-class="secondary white--text text--accent-4"
          show-arrows
        >
          <v-chip
            v-for="item in approvalGroups"
            :key="item.text"
            :value="item.text"
            class="ma-2"
            filter
            :outlined="item.text !== selectedGroup"
            color="primary"
          >
            {{ item.text }} ({{ item.value }})
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <v-divider />
    <v-card-text>
      <v-data-table :headers="headers" :items="approvals" class="elevation-0">
        <template v-slot:item.recordToApprove="{ item }">
          {{ item.recordToApprove }}
        </template>
        <template v-slot:item.dueDate="{ item }">
          <span>{{ getDate(item.dueDate) }}</span>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip :color="getStatusColor(item.status)">
            {{ item.status == "Open" ? "Pending Approval" : item.status }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            class="primary lighten-4 primary--text text--darken-4"
            elevation="0"
            @click="viewApproval(item)"
          >
            <v-icon class="mx-2">mdi-eye</v-icon>
            View
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
import { helper } from "@kinetics254/cassandra-base/utilities";
export default {
  name: "ApprovalList",
  data() {
    return {
      search: "",
      selectedGroup: "",
      tabs: "All",
      status: ["All", "Pending", "Approved"],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Approvals/getApprovalEntries", {
        approverID: v.userData.employee,
      });
    });
  },
  computed: {
    userData() {
      return AuthService.user;
    },
    approvals() {
      const approvals =
        this.$store.getters["Approvals/approvalGetters"]("approvalEntries");
      return approvals.data
        ? approvals.data
            .sort((a, b) => {
              return new Date(b.dueDate) - new Date(a.dueDate);
            })
            .filter((el) => {
              return this.tabs === "Pending" ? el.status === "Open" : el;
            })
            .filter((el) => {
              return this.tabs === "Approved" ? el.status !== "Open" : el;
            })
            .filter((el) =>
              this.selectedGroup.length > 0
                ? el.approveForType === this.selectedGroup
                : true
            )
        : [];
    },
    approvalGroups() {
      const approvals =
        this.$store.getters["Approvals/approvalGetters"]("approvalEntries");
      return approvals.groups
        ? approvals.groups.map((el) => {
            return {
              text: el.approveForType,
              value: approvals.data.filter(
                (item) => item.approveForType === el.approveForType
              ).length,
            };
          })
        : [];
    },
    employees() {
      return this.$store.getters["Setup/setupGetter"]("leaveEmployees");
    },
    headers() {
      return [
        {
          text: "No.",
          align: "start",
          sortable: true,
          value: "entryNo",
        },
        {
          text: "Document To Approve",
          align: "start",
          sortable: true,
          value: "approveForType",
        },
        {
          text: "Document Number",
          align: "start",
          sortable: true,
          value: "documentNo",
        },
        {
          text: "Sent By",
          align: "start",
          sortable: true,
          value: "sendByName",
        },
        {
          text: "Status",
          align: "start",
          sortable: true,
          value: "status",
        },
        {
          text: "Due Date",
          align: "start",
          sortable: true,
          value: "dueDate",
        },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
  },
  methods: {
    getEmployee(number) {
      const employee = this.employees.filter((e) => e.number === number)[0];
      return employee
        ? employee.search_name.length > 0
          ? employee.search_name
          : `${employee.first_name} ${employee.middle_name} ${employee.last_name}`
        : "";
    },
    getDate(date) {
      return helper.getDateFormat(date);
    },
    getStatusColor(status) {
      return helper.getColor(status).color;
    },
    splitHeader(item) {
      const RecHeader = item.recordToApprove.split(":")[0];
      console.log(RecHeader);
      return RecHeader;
    },
    viewApproval(item) {
      const RecHeader = item.recordToApprove.split(":")[0];
      console.log(RecHeader);
      if (RecHeader !== "Emp. Perf. Application Header") {
        this.$router.push({
          name: "ApprovalCard",
          params: {
            documentNo: btoa(item.documentNo),
            approverID: btoa(item.approverID),
          },
        });
      } else {
        this.$router.push({
          name: "AppraisalApprovalCard",
          params: {
            documentNo: btoa(item.documentNo),
          },
        });
      }
    },
  },
};
</script>
