import { LeaveRoutes, LeaveStore } from "./modules/leave";
import { LeaveSetupStore } from "./modules/setup";
// import { AllowanceStore, AllowanceRoutes, AllowanceLinks } from './modules/allowance'
import { HandoverStore, HandoverRoutes } from "./modules/handover";

import links from "./links";

export default {
  install(Vue, options) {
    /* register store and routes */
    if (options.router) {
      options.router.addRoute(...LeaveRoutes);
      // options.router.addRoute(...AllowanceRoutes)
      options.router.addRoute(...HandoverRoutes);
    }
    if (options.store) {
      options.store.registerModule("leave", LeaveStore);
      options.store.registerModule("leaveSetup", LeaveSetupStore);
      // options.store.registerModule('allowance', AllowanceStore)
      options.store.registerModule("handover", HandoverStore);

      /* register side links */
      options.store.commit("Dashboard/ADD_LINK", links);
    }
  },
};
