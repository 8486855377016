<template>
  <v-dialog v-model="dialog" width="1200">
    <v-card class="mx-auto mt-10" flat>
      <v-card-title>
        KPIs <v-spacer />

        <span class="ml-0 text-body-1">
          Application:
          <span class="font-weight-bold">{{ routeParams }}</span></span
        >
        <v-spacer />
        <span class="mr-2 text-body-1"> Status:</span>
        <v-chip dark :color="getStatusColor(singleApplication.status).color">
          <span
            class="black--text"
            v-if="singleApplication.status === 'Pending Approval'"
            >{{ singleApplication.status }}</span
          >
          <span v-else>{{ singleApplication.status }}</span>
        </v-chip>
        <v-chip color="primary">
          <span class="white--text">{{ singleApplication.name }}</span>
          <span class="white--text"> - </span>
          <span class="white--text">{{ singleApplication.employeeNo }}</span>
        </v-chip>
      </v-card-title>
      <v-card-title>
        <v-spacer />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="applicationLines"
        :search="search"
        class="elevation-0"
        fixed-header
      >
        <v-divider inset></v-divider>
        <!--eslint-disable-next-line -->
        <template v-slot:item.lineNo="{ item }" v-if="routeParams">
          {{ item.lineNo }}
        </template>
        <!--eslint-disable-next-line -->
        <template v-slot:item.code="{ item }">
          <v-autocomplete
            v-model="item.objectiveCode"
            :items="ObjectiveCodes"
            :item-text="(item) => item.description"
            :item-value="(item) => item.code"
            :hide-details="false"
            label="Select KPI"
            dense
            single-line
            class="mb-n3"
            @change="insertIndicator(item)"
          ></v-autocomplete>
        </template>
        <!--eslint-disable-next-line -->
        <template v-slot:item.kpiCode="{ item }">
          <v-autocomplete
            v-model="item.kpiCode"
            :items="item.indicators"
            :item-text="(item) => item.description"
            :item-value="(item) => item.code"
            :hide-details="false"
            label="Select Indicator"
            dense
            single-line
            class="mb-n3"
          ></v-autocomplete>
        </template>
        <!--eslint-disable-next-line -->
        <template v-slot:item.weight="{ item }">
          <v-text-field
            v-model.number="item.weight"
            :hide-details="false"
            type="number"
            label="Add weight"
            dense
            single-line
            class="mb-n3"
          ></v-text-field>
        </template>
        <!--eslint-disable-next-line -->
        <template v-slot:item.target="{ item }">
          <v-text-field
            v-model.number="item.target"
            :hide-details="false"
            type="number"
            label="Add Target"
            dense
            single-line
            class="mb-n3"
          ></v-text-field>
        </template>
        <!--eslint-disable-next-line -->
        <template v-slot:body.append>
          <tr>
            <th class="text-start"><h3>Total</h3></th>
            <th><h3></h3></th>
            <th><h3></h3></th>
            <th class="text-start">
              <h3>{{ weightTotal }}</h3>
            </th>
            <th class="text-start">
              <h3>{{ targetTotal }}</h3>
            </th>
          </tr>
        </template>
      </v-data-table>
      <v-divider />
      <v-card-actions>
        <v-btn class="pa-2" @click="GoBack()" color="error">
          <v-icon left>mdi-arrow-left</v-icon>
          Go Back
        </v-btn>
        <v-spacer />
        <v-btn
          @click="commentDialog = true"
          class="mr-2"
          color="error"
          depressed
        >
          Reject
        </v-btn>
        <!-- <v-btn @click="SendForApproval()" class="mr-2" color="yellow" depressed>
          Send For Approval
        </v-btn> -->
        <v-btn
          @click="SubmitWeights()"
          color="green"
          class="ma-2 white--text mr-2"
          depressed
        >
          Approve
        </v-btn>
      </v-card-actions>

      <v-dialog
        transition="dialog-bottom-transition"
        v-model="commentDialog"
        max-width="600"
      >
        <v-card>
          <v-toolbar color="red" dark> Reject Reason </v-toolbar>
          <v-card-text>
            <div class="text-h2 pa-3">
              <v-textarea
                clearable
                v-model="commentText"
                outlined
                clear-icon="mdi-close-circle"
                label="Reject Reason"
                model-value="This is clearable text."
              ></v-textarea>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="commentDialog = false">Close</v-btn>
            <v-spacer />
            <v-btn @click="Reject()" class="mr-2" color="error" depressed>
              Reject
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>
<script>
import { helper } from "../../../../cassandra-base/utilities";
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";

export default {
  name: "AppraisalApprovalCard",

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Template/getObjectiveCodes");
      if (atob(v.$route.params.documentNo)) {
        v.$store.dispatch(
          "Template/getLinesforOneApplication",
          atob(v.$route.params.documentNo)
        );
        v.$store.dispatch(
          "Template/getOneApplication",
          atob(v.$route.params.documentNo)
        );
        v.$store.dispatch("Template/getPendingApprovals");
        v.$store.dispatch(
          "Template/getApprovalEntries",
          atob(v.$route.params.documentNo)
        );
      }
    });
  },
  data: function () {
    return {
      dialog: true,
      commentDialog: false,
      search: "",
      commentText: "",
      values: [],
      applicationLines: [],
      expanded: [],
      headers: [
        {
          text: "LineNo",
          align: "start",
          sortable: false,
          value: "lineNo",
        },
        {
          text: " Select Objective ",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Indicator",
          align: "start",
          sortable: false,
          value: "kpiCode",
        },
        {
          text: "Weight",
          align: "start",
          sortable: false,
          value: "weight",
        },
        {
          text: "Target",
          align: "start",
          sortable: false,
          value: "target",
        },
      ],

      formData: {
        objectiveCode: "",
        kpiCode: "",
        weight: 0,
        target: 0,
      },
    };
  },
  computed: {
    ObjectiveCodes() {
      return this.$store.getters["Template/appTempGetters"]("objectiveCodes");
    },
    routeParams() {
      return atob(this.$route.params.documentNo);
    },
    ObjectiveLineswithParams() {
      return this.$store.getters["Template/appTempGetters"]("appLines");
    },
    singleApplication() {
      return this.$store.getters["Template/appTempGetters"]("oneApplication");
    },
    userData() {
      return AuthService.user;
    },
    approval() {
      const approvals =
        this.$store.getters["Approvals/approvalGetters"]("approvalEntries");
      return (
        approvals.data.filter((el) => {
          return el.documentNo === this.routeParams;
        })[0] || {}
      );
    },
    weightTotal() {
      return this.applicationLines.reduce((a, b) => a + b.weight, 0);
    },
    targetTotal() {
      return this.applicationLines.reduce((a, b) => a + b.target, 0);
    },
  },
  methods: {
    insertIndicator(item) {
      item.indicators = this.ObjectiveCodes.find(
        (d) => d.code === item.objectiveCode
      ).indicators;
    },

    SubmitWeights() {
      const newApplicationLines = this.applicationLines.map(
        ({ indicators, ...rest }) => rest
      );
      const data = {
        documentNo: this.routeParams,
        employeeApplicationLines: newApplicationLines,
      };

      this.approval.senderID = this.singleApplication.employeeNo;
      const allData = {
        KPIData: data,
        approveData: this.approval,
        applicationNo: this.routeParams,
      };

      this.$store.dispatch("Template/addKPIWeights", allData);
    },
    GoBack() {
      this.$router.push({ name: "ApprovalList" });
    },

    Reject() {
      this.commentDialog = true;
      this.approval["reason"] = this.commentText;
      this.$store.dispatch("Template/rejectApplication", this.approval);
      this.commentDialog = false;
      this.$router.push({ name: "ApprovalList" });
    },
    getStatusColor: function (status) {
      return helper.getColor(status);
    },
  },
  watch: {
    ObjectiveLineswithParams: {
      handler: function () {
        this.applicationLines = [...this.ObjectiveLineswithParams];
      },
    },
  },
};
</script>
<style scoped>
@import "../../appraisalv1/styles/appraisal.css";
</style>
