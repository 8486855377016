export default {
  kpis: "hrmis/appraisal/v2/template/kpis",
  objectives: "hrmis/appraisal/v2/template/objectives",
  objectiveCodes: "hrmis/appraisal/v2/template/objectiveCodes",
  sendForApproval: "hrmis/appraisal/v2/template/sendForApproval",
  cancelForApproval: "hrmis/appraisal/v2/template/cancelForApproval",
  createApplication: "hrmis/appraisal/v2/template/saveApplication",
  getApplications: "hrmis/appraisal/v2/template/allApplications",
  SINGLE: (id) => `hrmis/appraisal/v2/template/applicationLines/${id}`,
  editApplication: "hrmis/appraisal/v2/template/editApplicationLines",
  SINGLEAPP: (id) => `hrmis/appraisal/v2/template/application/${id}`,
  pendingApprovals: "hrmis/appraisal/v2/template/pendingApprovals",
  approve: "approvals/approve",
  reject: "approvals/reject",
  deleteLine: "hrmis/appraisal/v2/template/deleteLine",
  deleteApplication: "hrmis/appraisal/v2/template/deleteApp",
  appEntries: (id) => `approvals/approvalEntries?documentNo=${id}`,
};
