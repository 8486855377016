export default {
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Exit/getExits");
      v.$store.dispatch("Exit/getHrSetup");
    });
  },
  computed: {
    hrSetup() {
      return this.$store.getters["Exit/exitGetters"]("questionnaire");
    },
    exits() {
      return this.$store.getters["Exit/exitGetters"]("exits");
    },
    questionnairePath() {
      return `/feedback/feedback-card/${this.hrSetup.exitQuestionnaireCode}/${this.formData.no}/${this.userData.employee}`;
    },
    isQuestionnaire() {
      return this.formData.questionnaireAnswers
        ? this.formData.questionnaireAnswers.length === 0
        : false;
    },
  },
};
