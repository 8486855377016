import call from "@kinetics254/cassandra-base/service/http";
import constants from "./constants";

export default {
  namespaced: true,
  state: {
    templates: [],
    kpis: [],
    objectives: [],
    objectiveCodes: [],
    template: {},
    applications: [],
    appLines: [],
    approvalEntries: [],
    oneApplication: {},
    pendingApprovals: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    appTempGetters: (state) => (val) => state[val],
  },
  actions: {
    getKpis: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.kpis)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "kpis", data: res.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getObjectives: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.objectives)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "objectives", data: res.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getObjectiveCodes: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.objectiveCodes)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "objectiveCodes", data: res.data.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    sendForApproval: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.sendForApproval, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("getApplications");
          Event.$emit("ApiSuccess", res.data.message);
          Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    cancelForApproval: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.cancelForApproval, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("getOneApplication", data.applicationNo);
          Event.$emit("ApiSuccess", res.data.message);
          Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    cancelApproval: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.cancelApproval, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("getTemplates");
          Event.$emit("ApiSuccess", res.data.message);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    saveAndSendApplication: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.createApplication, data)
        .then((res) => {
          Event.$emit("ApiSuccess", "Saved Successfully");
          dispatch("sendForApproval", { applicationNo: res.data.data[0].no });
        })
        .catch((err) => {
          Event.$emit("ApiError", err.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    saveApplication: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.createApplication, data)
        .then(() => {
          Event.$emit("ApiSuccess", "Saved Successfully");
          Event.$emit("back");
          dispatch("getApplications");
        })
        .catch((err) => {
          Event.$emit("ApiError", err.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getApplications: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.getApplications)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "applications", data: res.data.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getLinesforOneApplication({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.SINGLE(payload))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "appLines", data: res.data.data });
        })
        .catch((err) => {
          Event.$emit("ApiError", err.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    editApplication: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.editApplication, data)
        .then(() => {
          Event.$emit("ApiSuccess", "Saved Successfully");
          Event.$emit("back");
          dispatch("getApplications");
        })
        .catch((err) => {
          Event.$emit("ApiError", err.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getOneApplication({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.SINGLEAPP(payload))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "oneApplication", data: res.data.data[0] });
        })
        .catch((err) => {
          Event.$emit("ApiError", err.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getPendingApprovals: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.pendingApprovals)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "pendingApprovals",
            data: res.data.data,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    approveApplication: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.approve, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", res.data.message);
          Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    addKPIWeights: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.editApplication, data.KPIData)
        .then(() => {
          Event.$emit("ApiSuccess", "Saved Added Weights");
          dispatch("approveApplication", data.approveData);
        })
        .catch((err) => {
          Event.$emit("ApiError", err.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    rejectApplication: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.reject, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", res.data.message);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    deleteLine: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.deleteLine, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("getLinesforOneApplication", data.documentNo);
          Event.$emit("ApiSuccess", res.data.message);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    deleteApplication: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.deleteApplication, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("getApplications");
          Event.$emit("ApiSuccess", res.data.message);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    editAndSendApplication: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.editApplication, data)
        .then(() => {
          Event.$emit("ApiSuccess", "Saved Successfully");
          dispatch("sendForApproval", { applicationNo: data.documentNo });
        })
        .catch((err) => {
          Event.$emit("ApiError", err.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getApprovalEntries({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.appEntries(payload))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "approvalEntries", data: res.data.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
  },
};
