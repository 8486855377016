<template>
  <div>
    <div class="label mx-4">Select Leave Type</div>
    <v-autocomplete
      :items="leaveTypes"
      placeholder="Leave Type"
      clearable
      item-text="description"
      item-value="code"
      filled
      class="mt-1 mx-4 leaveInputs"
      v-model="leaveTypelocal"
      :rules="rules"
    >
      <template v-slot:prepend-inner>
        <v-icon color="primary"> toc </v-icon>
      </template>
    </v-autocomplete>

    <v-combobox
      v-if="requiresReason"
      placeholder="Leave reason"
      clearable
      filled
      :rules="rules"
      class="mx-4 leaveInputs"
      :items="leaveReasons"
      item-text="description"
      item-value="code"
      :value="ReasonDescription"
      @input="input($event)"
    >
      <template v-slot:no-data>
        <v-list-item>
          <span class="subheading">hit enter to add description</span>
        </v-list-item>
      </template>

      <template v-slot:selection="{ item, selected }">
        <span
          class="pr-2"
          v-if="item === Object(item)"
          :input-value="selected"
        >
          {{ item.description }}
        </span>
        <span v-else>{{ item }}</span>
      </template>
    </v-combobox>
  </div>
</template>
<script>
export default {
  name: "LeaveType",
  props: {
    leaveTypes: Array,
    rules: Array,
    leave_type: String,
    displayLeaveType: String,
    leaveReasons: Array,
    ReasonCode: String,
    ReasonDescription: String
  },
  computed: {
    leaveTypelocal: {
      get() {
        return this.displayLeaveType;
      },
      set(value) {
        this.$emit("updateLeaveType", value);
      },
    },
    leaveReason:{
      get(){
        return this.ReasonCode
      },
      set(val){
        this.$emit("updateReason", val)
      }
    },
    leaveReasonDesc: {
      get() {
        return this.ReasonDescription
      },
      set(val){
        this.$emit("updateLeaveReasonDesc", val)
      }
    },
    requiresReason() {
      return this.leaveTypelocal
        ? this.leaveTypes.find((l) => l.code === this.leaveTypelocal)
            .requireReason
        : false;
    },
  },
  methods:{    
    input: function (event) {
      if (event && typeof event === "object") {
        this.leaveReason = event.code;
      } else {
        this.leaveReasonDesc = event;
      }
    },
  }
};
</script>
