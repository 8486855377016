import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto mt-10",attrs:{"flat":""}},[_c(VCardTitle,[_vm._v("Expense Items "),_c(VSpacer),(_vm.displayPettyCashActions)?_c(VBtn,{staticClass:"ml-2 white--text text-capitalize",attrs:{"color":"primary"},on:{"click":_vm.addNew}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-plus")]),_vm._v("Add Expense Item ")],1):_vm._e()],1),_c(VDataTable,{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.expenseItemsList,"search":_vm.search,"fixed-header":""},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [(_vm.displayConditionally(item))?_c(VAutocomplete,{staticClass:"mb-n3",attrs:{"items":_vm.expenseItems,"item-text":function (item) { return ((item.code) + " - " + (item.description)); },"item-value":function (item) { return item.code; },"hide-details":false,"label":"Select Expense Item","dense":"","single-line":""},on:{"change":_vm.selectExpenseCode,"focusout":_vm.autosaveExpenseItem},model:{value:(_vm.expenseItem.expense_code),callback:function ($$v) {_vm.$set(_vm.expenseItem, "expense_code", $$v)},expression:"expenseItem.expense_code"}}):_c('span',[_vm._v(_vm._s(item.expense_code))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [(_vm.displayConditionally(item))?_c(VTextField,{attrs:{"hide-details":true,"dense":"","single-line":""},on:{"focusout":_vm.autosaveExpenseItem},model:{value:(_vm.expenseItem.description),callback:function ($$v) {_vm.$set(_vm.expenseItem, "description", $$v)},expression:"expenseItem.description"}}):_c('span',[_vm._v(_vm._s(item.description))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.displayConditionally(item))?_c(VTextField,{attrs:{"hide-details":true,"dense":"","single-line":"","type":"number","onkeyup":"if(this.value<0){this.value= this.value * -1}","min":"1","disabled":_vm.hasTransportCode},on:{"focusout":_vm.autosaveExpenseItem,"input":function($event){return _vm.assignUnitCost($event, index)}},model:{value:(_vm.expenseItem.amount),callback:function ($$v) {_vm.$set(_vm.expenseItem, "amount", _vm._n($$v))},expression:"expenseItem.amount"}}):_c('span',[_vm._v(_vm._s(item.amount))])]}},{key:"item.invoiceRef",fn:function(ref){
var item = ref.item;
return [(_vm.displayConditionally(item))?_c(VTextField,{attrs:{"hide-details":true,"dense":"","single-line":""},on:{"focusout":_vm.autosaveExpenseItem},model:{value:(_vm.expenseItem.invoiceRef),callback:function ($$v) {_vm.$set(_vm.expenseItem, "invoiceRef", $$v)},expression:"expenseItem.invoiceRef"}}):_c('span',[_vm._v(_vm._s(item.invoiceRef))])]}},{key:"item.dimensions",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"ml-2 white--text text-caption font-weight-thin",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.addDimensions(item)}}},'v-chip',attrs,false),on),[_vm._v(" Dimensions ")])]}}],null,true)},[_c('span',[_vm._v("click to edit")])])]}},{key:"item.from",fn:function(ref){
var item = ref.item;
return [(_vm.displayConditionally(item))?_c(VTextField,{attrs:{"hide-details":true,"disabled":_vm.expenseItem.description.toLowerCase() !== 'travel',"dense":"","single-line":""},on:{"focusout":_vm.autosaveExpenseItem},model:{value:(_vm.expenseItem.from),callback:function ($$v) {_vm.$set(_vm.expenseItem, "from", $$v)},expression:"expenseItem.from"}}):_c('span',[_vm._v(_vm._s(item.from))])]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [(_vm.displayConditionally(item))?_c(VTextField,{attrs:{"hide-details":true,"disabled":_vm.expenseItem.description.toLowerCase() !== 'travel',"dense":"","single-line":""},on:{"focusout":_vm.autosaveExpenseItem},model:{value:(_vm.expenseItem.to),callback:function ($$v) {_vm.$set(_vm.expenseItem, "to", $$v)},expression:"expenseItem.to"}}):_c('span',[_vm._v(_vm._s(item.to))])]}},(_vm.showAllocationCode)?{key:"item.allocationCode",fn:function(ref){
var item = ref.item;
return [(_vm.displayConditionally(item))?_c(VAutocomplete,{staticClass:"mb-n3",attrs:{"items":_vm.allocationCodes,"item-text":function (item) { return ((item.no) + " - " + (item.description)); },"item-value":function (item) { return item.no; },"hide-details":false,"label":"Select Allocation Code","dense":"","single-line":""},on:{"focusout":_vm.autosaveExpenseItem},model:{value:(_vm.expenseItem.allocationCode),callback:function ($$v) {_vm.$set(_vm.expenseItem, "allocationCode", $$v)},expression:"expenseItem.allocationCode"}}):_c('span',[_vm._v(_vm._s(item.allocationCode))])]}}:null,(_vm.displayPettyCashActions)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',[(_vm.displayConditionally(item))?_c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"red"},on:{"click":_vm.close}},'v-icon',attrs,false),on),[_vm._v(" mdi-window-close ")])]}}],null,true)},[_c('span',[_vm._v("Close Editing")])]),_c(VIcon,{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteLine(item)}}},[_vm._v(" mdi-delete ")])],1):_c('div',[_c(VIcon,{staticClass:"mr-3",attrs:{"color":"green"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c(VIcon,{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteLine(item)}}},[_vm._v(" mdi-delete ")])],1)])]}}:null,{key:"body.append",fn:function(){return [_c('tr',[_c('th',{staticClass:"text-center"},[_c('h3',[_vm._v("TOTAL AMOUNT")])]),_c('th',[_c('h3')]),_c('th',[_c('h3')]),_c('th',{staticClass:"text-center"},[_c('h3',[_vm._v(_vm._s(_vm._f("currencyFormat")(_vm.pettyCashTotal)))])])])]},proxy:true}],null,true)},[_c(VDivider,{attrs:{"inset":""}})],1),_c('DimensionsDialog',{attrs:{"itemDialog":_vm.itemDialog,"selectedExpenseItem":_vm.expenseItem,"formData":_vm.formData,"locations":_vm.locations,"dimensionValues":_vm.dimensionValues,"displayPettyCashActions":_vm.displayPettyCashActions},on:{"close-dialog":_vm.closeDialog,"updateDimensions":_vm.updateDimensions}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }