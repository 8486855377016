import Auth from "@kinetics254/cassandra-base/modules/auth/middleware/Auth";
const AppDashboard = () =>
  import("@/packages/cassandra-base/app/layout/AppDashboard");
import TemplateList from "./components/TemplateList";
import TemplateCard from "./components/TemplateCard";

import ApprovalCard from "./components/ApprovalCard";
import AppraisalAcceptReview from "./components/AppraisalAcceptReview";

export default [
  {
    path: "/templates",
    component: AppDashboard,
    children: [
      {
        path: "",
        redirect: "/templates/list",
      },
      {
        path: "list",
        name: "TemplateList",
        components: {
          view: TemplateList,
        },
        meta: { middleware: [Auth] },
      },
      {
        path: "card/:no?",
        name: "TemplateCard",
        components: {
          view: TemplateList,
          action: TemplateCard,
        },
        meta: { middleware: [Auth] },
      },
      {
        path: "approvalCard/:documentNo",
        name: "AppraisalApprovalCard",
        components: {
          view: ApprovalCard,
        },
        meta: { middleware: [Auth] },
      },
      {
        path: "reviewee/:reviewerID",
        name: "AppraisalAcceptReview",
        components: {
          view: AppraisalAcceptReview,
        },
        meta: { middleware: [Auth] },
      },
    ],
  },
];
