<template>
  <div>
    <v-app-bar class="mx-auto mt-5" dense elevation="0" color="transparent">
      <div class="headline text-lg-h6 header-title">
        Leave HandOver Requests
      </div>
    </v-app-bar>
    <v-card elevation="0" class="mx-auto mt-4" flat>
      <v-card-text>
        <v-row>
          <v-col class="ml-md-4" cols="12" md="4" sm="12">
            <v-menu
              v-model="filterDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="auto"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  color="primary"
                  dense
                  label="Filter by Posting Date"
                  prepend-inner-icon="filter_alt"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterDate"
                range
                @input="
                  (filterDatePicker = false),
                    (startDate = filterDate[0]),
                    (endDate = filterDate[1])
                "
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-spacer />
          <v-col class="mr-md-4" cols="12" md="4" sm="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              class="mt-n3"
              hide-details
              label="Search"
              single-line
            />
          </v-col>
        </v-row>
        <v-row class="no-gutters mt-3">
          <v-col cols="12" md="12">
            <v-sheet class="">
              <div class="px-3 py-3">
                <v-data-table
                  :headers="headers"
                  :items="Applications"
                  :sort-desc="[false, true]"
                  multi-sort
                  class="elevation-1"
                  ref="HandOver_list"
                  :items-per-page="8"
                  id="scollStyle"
                  :search="search"
                  :sort-by.sync="sortBy"
                >
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.status="{ item }">
                    <v-chip label :color="getColor(item.status).color" small>
                      {{ item.status }}
                    </v-chip>
                  </template>

                  <!--eslint-disable-next-line -->
                  <template v-slot:item.made_by="{ item }">
                    <span>{{ AppSender(item) }}</span>
                  </template>
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.days_applied="{ item }">
                    <div style="font-size: 14px; font-weight: 400">
                      {{ `${item.days_applied} day(s)` }}
                    </div>
                  </template>
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.start_date="{ item }">
                    {{ getDateFormat(item.start_date) }}
                  </template>
                  <!--eslint-disable-next-line -->
                  <template v-slot:item.return_date="{ item }">
                    {{ getDateFormat(item.return_date) }}
                  </template>
                  <!--eslint-disable-next-line -->

                  <!--eslint-disable-next-line -->
                  <template v-slot:item.actions="{ item }">
                    <div class="d-flex justify-start">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            color="blue lighten-4"
                            v-bind="attrs"
                            v-on="on"
                            label
                            @click="viewHandOver(item)"
                            class="mx-1"
                          >
                            <v-icon color="blue darken-4">pageview</v-icon>
                          </v-chip>
                        </template>
                        <span>view application</span>
                      </v-tooltip>
                    </div>
                  </template>
                </v-data-table>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { SetupMixin } from "../../../mixins";
import helpers from "@kinetics254/cassandra-base/utilities/helpers/helper";

export default {
  name: "handOverRequests",
  mixins: [SetupMixin],
  data: function () {
    return {
      scroll: true,
      filterDate: [],
      filterDatePicker: "",
      sortBy: "no",
      sortDesc: true,
      search: "",
      type: "Handover",
    };
  },
  created() {},
  mounted() {},
  computed: {
    headers: function () {
      return [
        {
          text: "#Code",
          align: "start",
          sortable: true,
          value: "application_code",
        },
        { text: "Made By", sortable: true, value: "made_by" },
        { text: "Applied", sortable: true, value: "days_applied" },
        { text: "Leave Type", sortable: true, value: "leave_code" },
        { text: "Period", sortable: true, value: "leave_period" },
        { text: "Start Date", sortable: true, value: "start_date" },
        { text: "Return Date", sortable: true, value: "return_date" },
        { text: "Status", sortable: true, value: "status" },
        { text: "Actions", sortable: false, value: "actions" },
      ];
    },
    dateRangeText() {
      return this.filterDate.join(" To ");
    },

    Applications() {
      const handover =
        this.$store.getters["handover/handoverGetter"]("HandOverApprovals");
      return handover ? handover.filter((el) => !el.acceptHandover) : [];
    },
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("handover/getHandOverApprovals");
    });
  },
  watch: {
    filterDate() {
      if (this.filterDate.length === 1) {
        this.startDate = new Date(this.filterDate[0]);
        this.endDate = new Date(this.filterDate[0]);
      }
      if (this.filterDate.length === 2) {
        this.startDate = new Date(this.filterDate[0]);
        this.endDate = new Date(this.filterDate[1]);
      }
    },
  },
  methods: {
    filterlist(statusfilter) {
      this.status = statusfilter;
    },
    viewHandOver: function (application) {
      console.log(application);
      this.$router.push({
        name: "LeaveForm",
        params: { application_code: application.application_code },
      });
    },
    AppSender: function (sender) {
      const Sender = this.leaveEmployeeAll.filter(
        (emp) => emp.number === sender.employee_no
      );
      if (Sender.length === 0) {
        return "";
      } else {
        return Sender[0].first_name + " " + Sender[0].last_name;
      }
    },
    Accept: function (val) {
      if (val.acceptHandover === true) {
        return "Accepted";
      } else {
        if (val.acceptHandover === false && val.handoverComment !== "") {
          return "Rejected";
        }
      }
    },
    buttonStyles(val) {
      if (val.acceptHandover === true) {
        return "success";
      } else {
        if (val.acceptHandover === false && val.handoverComment !== "") {
          return "error";
        }
      }
    },
    getEntryDateFormat: function (date) {
      return helpers.getEntryDateFormat(date);
    },
    getDateFormat: function (date) {
      return helpers.getDateFormat(date);
    },
    getColor: function (status) {
      return helpers.getColor(status);
    },
  },
};
</script>

<style scoped>
@import url("../styles.css");
</style>
