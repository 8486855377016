import { AppraisalRoutes, AppraisalStore } from "./modules/appraisalv1";
import AppraisalLinks from "./AppraisalLinks";
import { TemplateRoutes, TemplateStore } from "./modules/appraisalv2";

export default {
  install(Vue, options) {
    /* register store and routes */
    if (options.router) options.router.addRoute(...AppraisalRoutes);
    if (options.router) options.router.addRoute(...TemplateRoutes);
    if (options.store)
      options.store.registerModule("appraisal", AppraisalStore);
    if (options.store) options.store.registerModule("Template", TemplateStore);

    options.store.commit("Dashboard/ADD_LINK", AppraisalLinks);
  },
};
