import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto",attrs:{"flat":""}},[_c(VCardTitle,[_vm._v(" My Approval List "),_c(VSpacer),_c(VToolbar,{attrs:{"dense":"","flat":""}},[_c(VBtnToggle,{attrs:{"active-class":"toggle--active","borderless":"","dense":"","mandatory":"","rounded":""},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.status),function(item,i){return _c(VBtn,{key:i,attrs:{"color":item === _vm.tabs ? 'primary' : 'default',"elevation":item === _vm.tabs ? 8 : 0,"rounded":item === _vm.tabs,"value":item,"small":""}},[_c('span',{staticClass:"text-capitalize",style:({ color: item === _vm.tabs ? '#ffffff' : '' })},[_vm._v(_vm._s(item === "Released" ? "Approved" : item && item === "Pending Approval" ? "Pending" : item))])])}),1)],1),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDivider),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VChipGroup,{attrs:{"active-class":"secondary white--text text--accent-4","show-arrows":""},model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}},_vm._l((_vm.approvalGroups),function(item){return _c(VChip,{key:item.text,staticClass:"ma-2",attrs:{"value":item.text,"filter":"","outlined":item.text !== _vm.selectedGroup,"color":"primary"}},[_vm._v(" "+_vm._s(item.text)+" ("+_vm._s(item.value)+") ")])}),1)],1)],1),_c(VDivider),_c(VCardText,[_c(VDataTable,{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.approvals},scopedSlots:_vm._u([{key:"item.recordToApprove",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.recordToApprove)+" ")]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getDate(item.dueDate)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":_vm.getStatusColor(item.status)}},[_vm._v(" "+_vm._s(item.status == "Open" ? "Pending Approval" : item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"primary lighten-4 primary--text text--darken-4",attrs:{"elevation":"0"},on:{"click":function($event){return _vm.viewApproval(item)}}},[_c(VIcon,{staticClass:"mx-2"},[_vm._v("mdi-eye")]),_vm._v(" View ")],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }