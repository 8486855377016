<template>
  <div>
    <v-container>
      <AppBar :path="path" />
      <v-card elevation="0">
        <v-card class="d-flex" flat>
          <v-card class="mr-auto my-2 mx-2" flat>
            <div class="d-flex justify-start">
              <!-- <span class="text-h6 size"> -->
              <h1 class="subtitle-2 font-weight-bold mt-1" color="">
                Application Code:
              </h1>
              <!-- </span> -->
              <div class="mx-2 pt-1">
                <v-chip color="primary" class="mt-n1" label>
                  {{
                    data.application_code !== undefined
                      ? data.application_code
                      : ""
                  }}
                </v-chip>
              </div>
              <div class="mx-2 text-center mt-n1">
                <!-- <TopBar :data="data"/> -->
              </div>
            </div>
          </v-card>
          <v-card class="pa-2 d-flex justify-end" flat v-if="data.sendApproval">
            <div class="mx-2">
              <v-btn class="btnActions" @click="$router.back()">
                <v-icon class="mx-3">arrow_back</v-icon>
                go back
              </v-btn>
            </div>
          </v-card>
          <v-card class="pa-2 d-flex justify-end" flat>
            <div class="mx-2">
              <v-btn
                color="error"
                class="btnActions"
                @click="approveApplication(data)"
              >
                <v-icon class="mx-3">thumb_down_alt </v-icon>
                reject
              </v-btn>
            </div>
            <div class="mx-2">
              <v-btn
                color="success"
                class="btnActions"
                @click.prevent="approveApplication({ data, type })"
              >
                <v-icon class="mx-3">thumb_up_alt</v-icon>
                Accept
              </v-btn>
            </div>
          </v-card>
        </v-card>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card flat>
                <v-card-title class="justify-center size mt-n4">
                  Leave Info
                </v-card-title>
                <v-card-title class="font-weight-black justify-start">
                  <h3 class="testcolor body-2">Leave Type:</h3>
                  <span style="color: #3f51b5">
                    <h1 class="subtitle-2 font-weight-bold ml-2" color="">
                      {{ data.leave_code }}
                    </h1>
                  </span>
                  <v-spacer />
                  <h3 class="testcolor body-2">Job Title:</h3>
                  <span style="color: #3f51b5">
                    <h1 class="subtitle-2 font-weight-bold ml-2" color="">
                      {{ JobTitle ? JobTitle : "N/A" }}
                    </h1>
                  </span>
                </v-card-title>
                <v-card-title class="justify-start">
                  <h3 class="testcolor body-2">Handed Over By:</h3>
                  <span style="color: #3f51b5">
                    <h1 class="subtitle-2 font-weight-bold ml-2" color="">
                      {{ resolveSender() }}
                    </h1>
                  </span>
                </v-card-title>
              </v-card>
            </v-col>
            <v-divider vertical />
            <v-col cols="12" md="6">
              <v-card flat>
                <v-card-title class="justify-center size mt-n4">
                  Leave Period
                </v-card-title>
                <v-card-text>
                  <Duration :data="data" :previewApplication="true" />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
    <!-- <Accept /> -->
    <accept-dialog
      :HandOver="HandOver"
      :dialog="dialog"
      v-on:close-dialog="closeDialog"
    />
  </div>
</template>
<style>
.timeline1.v-timeline:before {
  width: 5px;
}
</style>
<script>
import { SetupMixin } from "../../../mixins";
import AppBar from "../../leave/components/AppBar.vue";
// import TopBar from '../../leave/components/TopBar.vue'
import AcceptDialog from "./AcceptDialog.vue";
import Duration from "../../leave/components/LeaveDuration.vue";
export default {
  name: "LeaveForm",
  mixins: [SetupMixin],
  components: { Duration, AppBar, AcceptDialog },
  data: function () {
    return {
      items: [1, 2, 3],
      Status: false,
      counter: 1,
      path: this.$router.currentRoute.meta.breadcrumb,
      dialog: false,
      type: "Accept",
      HandOver: {},
    };
  },
  created() {
    this.$store.dispatch("handover/getApplication", { ...this.$route.params });
  },
  computed: {
    userData: function () {
      return this.$store.getters["auth/user"];
    },
    preview: function () {
      return this.$store.getters["handover/handoverGetter"]("preview");
    },
    leaveAttachment: function () {
      return this.$store.getters["handover/handoverGetter"]("leaveAttachment");
    },

    isApproval: function () {
      return this.leaveEmployee[0] !== undefined
        ? this.leaveEmployee[0].is_approver
        : false;
    },
    data: function () {
      const application =
        this.$store.getters["handover/handoverGetter"]("application");
      const leaveType = this.leaveTypesAll.filter(
        (type) => type.code === application.leave_code
      )[0];
      application.leaveType = leaveType === undefined ? {} : leaveType;
      return application;
    },
    AppSender: function () {
      const Sender = this.leaveEmployeeAll.filter(
        (e) => e.number === this.data.employee_no
      );

      if (Sender.length === 0) {
        return "";
      } else {
        return (
          Sender[0].first_name +
          " " +
          Sender[0].middle_name +
          " " +
          Sender[0].last_name
        );
      }
    },
    JobTitle: function () {
      const Sender = this.leaveEmployeeAll.filter(
        (e) => e.number === this.data.employee_no
      );
      if (Sender.length === 0) {
        return "";
      } else {
        return Sender[0].jobTitle;
      }
    },
  },
  methods: {
    closeDialog: function (val) {
      this.dialog = val;
    },
    approveApplication: function (val) {
      this.dialog = true;
      // console.log(val)
      this.HandOver = val;
    },
    resolveSender() {
      if (this.leaveEmployeeAll) {
        return this.AppSender;
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped>
.size {
  font-size: 15px;
}
.testcolor {
  color: rgb(43, 47, 71);
  font-weight: bold;
}
</style>
