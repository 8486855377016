import { render, staticRenderFns } from "./RequestCardActions.vue?vue&type=template&id=63609813"
import script from "./RequestCardActions.vue?vue&type=script&lang=js"
export * from "./RequestCardActions.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports