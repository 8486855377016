<template>
  <v-dialog v-model="dialog" max-width="75%" persistent>
    <v-card class="mx-auto" flat>
      <v-card-title>
        My KPIs <v-spacer />

        <span class="ml-0 text-body-1" v-if="routeParams">
          Application:
          <span class="font-weight-bold">{{ routeParams }}</span></span
        >
        <v-spacer />
        <span class="text-body-1" v-if="routeParams"> Status:</span>
        <v-chip
          v-if="routeParams"
          class="mr-5"
          small
          dark
          :color="getStatusColor(singleApplication.status).color"
        >
          {{ singleApplication.status }}
        </v-chip>
        <v-btn
          class="mx-2 text-capitalize deep-orange lighten-4 deep-orange--text text--darken-4"
          elevation="0"
          small
          @click="GoBack()"
        >
          close
          <v-icon class="mx-2">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-title>
       
        <v-spacer />

        <v-btn
          color="primary"
          class="ml-2 white--text"
          @click="addNew"
          v-if="isEdit"
        >
          <v-icon dark>mdi-plus</v-icon>Add KPI
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="applicationLines"
        :search="search"
        class="elevation-0"
        fixed-header
      >
        <v-divider inset></v-divider>
        <!--eslint-disable-next-line -->
        <template v-slot:item.code="{ item }">
          <v-autocomplete
            v-model="item.objectiveCode"
            :items="ObjectiveCodes"
            :item-text="(item) => item.description"
            :item-value="(item) => item.code"
            :hide-details="false"
            label="Select KPI"
            :disabled="!isEdit"
            dense
            single-line
            class="mb-n3 autogrow"
            @change="insertIndicator(item)"
          ></v-autocomplete>
        </template>
        <!--eslint-disable-next-line -->
        <template v-slot:item.kpiCode="{ item, index }">
          <v-combobox
            :items="item.indicators"
            :item-text="(item) => item.description"
            :item-value="(item) => item.code"
            :hide-details="false"
            :disabled="!isEdit"
            :value="item.kpiDescription"
            @input="input($event, index)"
            label="Select Indicator"
            dense
            single-line
            class="mb-n3 autogrow"
          >
            <template v-slot:no-data>
              <v-list-item>
                <span class="subheading">hit enter to add new</span>
              </v-list-item>
            </template>

            <template v-slot:selection="{ item, selected }">
              <span
                class="pr-2"
                v-if="item === Object(item)"
                :input-value="selected"
              >
                {{ item.description }}
              </span>
              <span v-else>{{ item }}</span>
            </template>
          </v-combobox>
        </template>

        <!--eslint-disable-next-line -->
        <template v-slot:item.weight="{ item }">
          <div v-if="stage.allowOwnerEdit && isEdit">
            <v-text-field
              v-model.number="item.weight"
              :hide-details="false"
              type="number"
              label="Add weight"
              dense
              single-line
              class="mb-n3"
            ></v-text-field>
          </div>
          <div v-else>{{ item.weight }}</div>
        </template>
        <!--eslint-disable-next-line -->
        <template v-slot:item.target="{ item }">
          <div v-if="stage.allowOwnerEdit && isEdit">
            <v-text-field
              v-model.number="item.target"
              :hide-details="false"
              type="number"
              label="Add Target"
              dense
              single-line
              class="mb-n3"
            ></v-text-field>
          </div>
          <div v-else>
            {{ item.target }}
          </div>
        </template>
        <!--eslint-disable-next-line -->
        <template v-slot:item.actions="{ item }" v-if="isEdit">
          <div>
            <v-icon color="green" class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon
              color="red"
              @click="routeParams ? deleteItem(item) : remove(item)"
            >
              mdi-delete
            </v-icon>
          </div>
        </template>
        <!--eslint-disable-next-line -->
        <template v-slot:body.append>
          <tr>
            <th class="text-start"><h3>Total</h3></th>
            <th><h3></h3></th>
            <th class="text-start">
              <h3>{{ weightTotal }}</h3>
            </th>
            <th class="text-start">
              <h3>{{ targetTotal }}</h3>
            </th>
          </tr>
        </template>
      </v-data-table>
      <v-divider />
      <ApprovalEntries :approvalEntries="approvalEntries" v-if="!isEdit" />
      <v-card-actions>
        <v-btn
          v-if="singleApplication.status === 'Pending Approval' && routeParams"
          @click="CancelForApproval()"
          class="mr-2"
          color="error"
          depressed
        >
          Cancel Approval
        </v-btn>
        <v-btn
          v-if="isEdit"
          @click="
            SaveApplication({
              isSendForApproval: true,
            })
          "
          class="mr-2"
          color="yellow"
          depressed
        >
          Save and Send For Approval
        </v-btn>
        <v-btn
          v-if="isEdit"
          @click="
            SaveApplication({
              isSendForApproval: false,
            })
          "
          class="mr-2"
          color="primary"
          depressed
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { helper } from "../../../../cassandra-base/utilities";
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
import ApprovalEntries from "./ApprovalEntries.vue";

export default {
  name: "TemplateCard",
  components: {
    ApprovalEntries,
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Template/getObjectiveCodes");
      v.$store.dispatch("appraisal/getPeriods");
      if (v.$route.params.no) {
        v.$store.dispatch(
          "Template/getLinesforOneApplication",
          v.$route.params.no
        );
        v.$store.dispatch("Template/getOneApplication", v.$route.params.no);
        v.$store.dispatch("Template/getApprovalEntries", v.$route.params.no);
      }
    });
  },
  data: function () {
    return {
      dialog: true,
      search: "",
      values: [],
      applicationLines: [],
      expanded: [],
      // headers: [
      //   {
      //     text: ` Select ${this.kpiObjectiveCaption} `,
      //     align: "start",
      //     sortable: false,
      //     value: "code",
      //   },
      //   {
      //     text: "Indicator",
      //     align: "start",
      //     sortable: false,
      //     value: "kpiCode",
      //   },
      //   {
      //     text: "Weight",
      //     align: "start",
      //     sortable: false,
      //     value: "weight",
      //   },
      //   {
      //     text: "Target",
      //     align: "start",
      //     sortable: false,
      //     value: "target",
      //   },

      //   { text: "Actions", value: "actions", sortable: false },
      // ],
      defaultObjectiveItem: {
        ObjectiveCode: "",
        kpiCode: "",
        weight: 0,
        target: 0,
      },
      formData: {
        objectiveCode: "",
        kpiCode: "",
        kpiDescription: "",
        weight: 0,
        target: 0,
        id: 0,
      },
    };
  },
  computed: {
    ObjectiveCodes() {
      return this.$store.getters["Template/appTempGetters"]("objectiveCodes");
    },
    routeParams() {
      return this.$route.params.no;
    },
    ObjectiveLineswithParams() {
      return this.$store.getters["Template/appTempGetters"]("appLines");
    },
    singleApplication() {
      return this.$store.getters["Template/appTempGetters"]("oneApplication");
    },
    approvalEntries() {
      return this.$store.getters["Template/appTempGetters"]("approvalEntries");
    },
    userData() {
      return AuthService.user;
    },
    isEdit() {
      return this.routeParams !== undefined
        ? this.singleApplication.status === "Open"
        : true;
    },
    weightTotal() {
      return this.applicationLines.reduce((a, b) => a + b.weight, 0);
    },
    targetTotal() {
      return this.applicationLines.reduce((a, b) => a + b.target, 0);
    },
    periods() {
      return this.$store.getters["appraisal/periods"];
    },
    stage() {
      return this.periods
        ? this.periods
            .find((p) => p.current)
            .periodStages.find((s) => s.current) || {}
        : {};
    },
    kpiObjectiveCaption() {
      return this.userData.captions.kpiObjective_caption;
    },
    headers(){

      return [
        {
          text: ` Select ${this.kpiObjectiveCaption ?? "Objective"} `,
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Indicator",
          align: "start",
          sortable: false,
          value: "kpiCode",
        },
        {
          text: "Weight",
          align: "start",
          sortable: false,
          value: "weight",
        },
        {
          text: "Target",
          align: "start",
          sortable: false,
          value: "target",
        },

        { text: "Actions", value: "actions", sortable: false },
      ];
    } 
  },
  methods: {
    addNew() {
      this.applicationLines.unshift({ ...this.formData });
      this.applicationLines.forEach(function (item, index) {
        item.id = index;
        // or do whatever you want using index
      });
    },
    insertIndicator(item) {
      item.indicators = this.ObjectiveCodes.find(
        (d) => d.code === item.objectiveCode
      ).indicators;
    },
    SaveApplication({ isSendForApproval }) {
      const newApplicationLines = this.applicationLines.map(
        ({ indicators, ...rest }) => rest
      );
      const data = {
        employeeApplicationLines: newApplicationLines,
      };
      if (this.routeParams !== undefined) {
        data.documentNo = this.routeParams;
      }
      console.log("data", data)
      if (isSendForApproval) {
        this.$store.dispatch(
          `Template/${
            this.routeParams !== undefined
              ? "editAndSendApplication"
              : "saveAndSendApplication"
          }`,
          data
        );
      } else {
        this.$store.dispatch(
          `Template/${
            this.routeParams !== undefined
              ? "editApplication"
              : "saveApplication"
          }`,
          data
        );
      }
    },
    GoBack() {
      this.$router.push({ name: "TemplateList" });
    },
    CancelForApproval() {
      const data = {
        applicationNo: this.routeParams,
      };
      this.$store.dispatch("Template/cancelForApproval", data);
    },
    getStatusColor: function (status) {
      return helper.getColor(status);
    },
    deleteItem(val) {
      this.$store.dispatch("Template/deleteLine", val);
    },
    remove(val) {
      this.applicationLines.splice(val.id, 1);
    },
    input: function (event, index) {
      if (event && typeof event === "object") {
        this.applicationLines[index].kpiCode = event.code;
        this.applicationLines[index].kpiDescription = event.description;
      } else {
        this.applicationLines[index].kpiCode = "";
        this.applicationLines[index].kpiDescription = event;
      }
      console.log("event", this.applicationLines[index]);
    },
  },
  watch: {
    ObjectiveLineswithParams: {
      handler: function () {
        this.applicationLines = [...this.ObjectiveLineswithParams];
      },
    },
    // kpiObjectiveCaption:{
    //   handler(val, oldVal) {
    //     console.log('changed')
    //   },
    //   deep: true
    // }
  },
};
</script>
<style scoped>
@import "../../appraisalv1/styles/appraisal.css";
</style>
